export const state = {
  api: undefined, // set in on_load()
  setMeData: undefined,
};

export function setupAuthAPISession(api, setMeData) {
  state.api = api;

  if (setMeData) {
    state.setMeData = setMeData;
  }
}

export function userAuth(data, handler) {
  state?.api?.call("user_auth", data, (msg, error, topic) => {
    handler?.(msg, error, topic);
  });
}

export function getUserList(data, handler) {
  state?.api?.call("user_list", {}, (msg, error, topic) => {
    handler?.(msg, error, topic);
  });
}

export function deleteUser(data, handler) {
  state?.api?.call("user_del", data, (msg, error, topic) => {
    handler?.(msg, error, topic);
  });
}

export function resetUser(data, handler) {
  state?.api?.call("user_reset", data, (msg, error, topic) => {
    handler?.(msg, error, topic);
  });
}

export function createUser(data, handler) {
  state?.api?.call("user_add", data, (msg) => {
    handler?.(msg);
  });
}

export function toggleAdmin(data, handler) {
  state?.api?.call("toggle_org_admin", data, (msg) => {
    handler?.(msg);
  });
}

export function getUserSSO(handler) {
  state?.api?.call("user_sso", {}, (msg) => {
    handler?.(msg);
  });
}

export function getUserSSOSession(data, handler) {
  state?.api?.call("auth_sso", data, (msg) => {
    handler?.(msg);
  });
}

export function getActiveUsers(handler) {
  state?.api?.call("active_user_session", {}, (msg) => {
    handler?.(msg);
  });
}

export function checkProxyStatus(data, handler) {
  state?.api?.call("service_check", data, (msg) => {
    handler?.(msg);
  });
}
