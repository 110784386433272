import { Card as AntdCard, Tag } from "antd";
import { useNavigate } from "react-router-dom";

import NoImage from "assets/images/img/no-image.jpg";

import Styles from "./styles/card.module.scss";
import { useAppContext } from "context/appContext";
import { useEffect } from "react";
import { getApp } from "api/proxy/admin-api";

const { Meta } = AntdCard;

const Card = ({
  children,
  title,
  className,
  heading,
  like,
  cover,
  coverHeight,
  status,
  onDelete,
  item,
  isAdmin,
  appsContext,
  ...props
}) => {
  const { meData } = useAppContext();
  const navigate = useNavigate();
  const handleCardClick = (id) => {
    if (meData && item.uid) {
      console.log("Data", {
        admin: meData.org_admin,
        user: meData.user,
        uid: item.uid,
      });
      getApp(
        { admin: meData.org_admin, user: meData.user, uid: item.uid },
        (msg) => {
          console.log("MSG", msg);
          if (msg) {
            navigate(`/app/${id}`);
          } else {
            console.log("NO MSG from get APP>>>>>>> redirecting to error");
            navigate("/error");
          }
        }
      );
    } else {
      console.log("ME DATA not loaded>>>>>>> redirecting to error");
      navigate("/error");
    }
  };

  return (
    <AntdCard
      title={title}
      className={`${Styles.card} ${className ? className : ""} ${
        !item.alive ? Styles.disabled : ""
      }`}
      cover={
        <img
          src={cover ? cover : NoImage}
          height={coverHeight || "auto"}
          alt=""
        />
      }
      {...props}
      onClick={item.alive ? () => handleCardClick(item.uid) : () => {}}
    >
      {!item.alive && <Tag className={Styles.tag}>Unavailable</Tag>}
      <div className={Styles.head}>
        {(heading || like) && (
          <div className={Styles.heading}>
            <p>{heading}</p>
            {like !== undefined && <div className={Styles.like}>{like}</div>}
          </div>
        )}
        {/* <Space className={Styles.space}>
          <Button icon={<DnsOutlinedIcon />}>Duplicate</Button>
          <Button
            type="primary"
            icon={<RocketLaunchOutlinedIcon />}
            onClick={() => handleLaunchClick(item.uid, appsContext)}
            disabled={!item.alive}
          >
            Open
          </Button>
        </Space> */}
      </div>
      {children}
    </AntdCard>
  );
};

Card.Meta = Meta;

export default Card;
