import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Empty, Spin } from "antd";

import { useAppContext } from "context/appContext";
import { getApp } from "api/proxy/admin-api";

import Styles from "./styles/appDetails.module.scss";

const AppDetails = () => {
  const { meData } = useAppContext();
  const { id } = useParams();
  const [isValidApp, setIsValidApp] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const isLocalApp = Boolean(process.env.REACT_APP_IS_LOCAL);
  const localAppLink = process.env.REACT_APP_APP_URL;
  // const [src,setSrc]=useState(`https://dev.morfius.ai/app/M0CK13LD71E6PQ1K`)
  useEffect(() => {
    const iframe = document.getElementById("app");

    // Check if the iframe has loaded
    iframe.addEventListener("load", () => {
      // Access the URL of the iframe's content
      console.log(iframe.contentWindow.location);
      const iframeUrl = iframe.contentWindow.location.href;
      if (!iframeUrl.includes("app")) {
        console.log("Iframe URL is without app:", iframeUrl);

        // iframe.src = `https://dev.morfius.ai/app/M0CK13LD71E6PQ1K`;
        // iframe.contentWindow.location.reload();
        window.location.reload();
      }
      console.log("Iframe URL:", iframeUrl);
    });
  },[]);
  
  console.log("window.location.origin",`${window.location.origin}/app/${id}`)
  return (
    <div className={Styles["app-details-wrapper"]}>
      {isLoading ? (
        <Spin />
      ) : (
        <>
          {
            <iframe
              src={`${
                isLocalApp ? localAppLink : window.location.origin
              }/app/${id}`}
              title={id}
              id="app"
            />
          }
        </>
      )}
    </div>
  );
};

export default AppDetails;
